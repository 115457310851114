






























































































































































import { Component, Mixins } from 'vue-property-decorator'
import { FormHelper } from '@/mixins'
import { AuthModule } from '@/store/modules'
import fillStore from '@/services/fillStore'
import { Auth } from '@/api'
import { LocalStorageManager } from '@/services/Managers'
import { themeConfig } from '@/config/themeConfig'

@Component({
  components: {
    BackLinks: () => import('@/components/Backlinks.vue'),
    Logo: () => import('@/components/Logo.vue'),
    TwoFactorAuthModal: () => import('@/components/Settings/2FAModal.vue')
  }
})
export default class Login extends Mixins(FormHelper) {
  @AuthModule.Action login

  serverName = themeConfig.app.name
  status = false
  rememberMe = false
  keepMeLoggedIn = true
  userEmail = LocalStorageManager.getItem('OGG_Email') || ''
  password = ''
  userData = {}
  error = false
  errorMessage = ''
  showLoading = false

  showAlertSessionExpired = false
  showSuccessValidatedAccount = false
  showErrorValidatedAccount = false

  $refs!: {
    loginForm: HTMLFormElement
  }

  async mounted() {
    document.addEventListener('keydown', e => this.handleEnterKey(e.key, this.validateForm))

    if (this.$route.query.sessionExpired) {
      this.showAlertSessionExpired = true
    }

    const validationToken = this.$route.query.validationToken as string

    if (!validationToken) return

    const isValidationTokenValid = await Auth.validateAccount(validationToken)

    if (isValidationTokenValid) {
      this.showSuccessValidatedAccount = true
    } else {
      this.showErrorValidatedAccount = true
    }
  }

  async beforeDestroy() {
    document.removeEventListener('keydown', e => this.handleEnterKey(e.key, this.validateForm))
  }

  async validateForm() {
    const userLoginDetails: LoginInfosForm = {
      email: this.userEmail,
      password: this.password,
      keepMeLoggedIn: this.keepMeLoggedIn
    }
    const isFormValid = await this.$refs.loginForm.validate()

    if (!isFormValid) {
      this.showLoading = false
      return
    }

    if (this.rememberMe) {
      LocalStorageManager.setItem('OGG_Email', this.userEmail)
    }

    const data = await this.login(userLoginDetails)

    if (data?.key === 'OGG_BAD_REQUEST_2FA_MISSING') {
      this.$root.$emit('openTwoFactorAuthModal', userLoginDetails)
      this.showLoading = false

      return
    }

    const { token } = data

    if (!token) {
      this.showLoading = false
      this.error = true
      this.errorMessage = this.$t(`RESPONSE_MESSAGES.${data.key}`) as string

      return
    }

    await fillStore()
    this.$router.push('/')
  }
}

